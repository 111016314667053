:root {
  --theme-color: #c71585;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  background-color: #f0f0f0;
}

/* .App {
  text-align: center;
  padding: 20px;
}

.header {
  background-color: var(--theme-color);
  padding: 20px;
  color: white;
}

.header h1 {
  margin: 0;
  font-size: 2em;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--theme-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.button:hover {
  background-color: #a50e6d;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.card {
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.card h2 {
  font-size: 1.2em;
  margin: 0 0 10px;
} */